import { ENV } from './config';

// 阿里云OSS
let OSSInfo;

// 微信三方开发平台
let WXOpenInfo = {
  appid: 'wx5628e477ff21b243',
  redirect_uri: '',
  response_type: 'code',
  scope: 'snsapi_login'
}

// 区分环境变量
if (ENV === 'dev') {
  OSSInfo = {
    TEMP_PATH: 'temp',
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAIGzMXuPTFuVzx',
    accessKeySecret: '2UgtRkeu11SflmGusvJMsJMKWFbcEH',
    secure: true,
    bucket: 'shou-upload-test'
  };

  WXOpenInfo.redirect_uri = 'http://passport.k12-code.com/third/wxLogin';

} else {
  OSSInfo = {
    TEMP_PATH: 'temp',
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI5tBUTDhT73fPXCjBgYuC',
    accessKeySecret: 'DdzM7FOhnQygsSpvDVM7HyxvJvMHhL',
    secure: true,
    bucket: 'kaisiao-users-bucket'
  }

  WXOpenInfo.redirect_uri = 'http://passport.k12-code.com/third/wxLogin';

}

export {
  OSSInfo,
  WXOpenInfo
}
