import { getHomeResourceUrl } from './config';
import { WXOpenInfo } from './secret';
import { queryStringify } from '../fetch-api';


// 静态属性以及资源
export const headerHeight = 64;
export const bannerHeight = 540;
export const animationTime = 0.3;
export const animationDelay = 0.1;
export const headerFixedColor = 'rgb(241, 129, 70)';

// logo & 首页小图标
// export const headerLogo = require('static/images/logo-short-small.png');
export const headerLogo = require('static/images/logo-short-small2.png'); // 备案需要用
export const img_king = require('static/images/king.png');
export const img_dianzan = require('static/images/dianzan.png');
export const img_topic = require('static/images/topic.png');
export const img_medal = require('static/images/medal.png');
export const qq_blue = require('static/images/qq_blue.png');
// 默认头像
export const defaultAvatar = require('static/images/defaultAvatar_small2.png');
export const noLoginAvatar = require('static/images/defaultAvatar_small.png');

// banner
export const bannerToy = getHomeResourceUrl('toy_fantasy.png')
export const notFoundImg = getHomeResourceUrl('404.png')
export const czyShopErcode = getHomeResourceUrl('czy_ercode.jpg')

// 轮播
const swipers = [
  getHomeResourceUrl('swiper1-2.png'),
  getHomeResourceUrl('swiper2.png'),
  getHomeResourceUrl('swiper3.png')
];

// 页底
export const companyCode = getHomeResourceUrl('wx_company_code.png');
export const gzhCode = getHomeResourceUrl('wx_gzh_code.png');

// 作品中心页
export const emptyWork = getHomeResourceUrl('noWork2.png');
export const emptyPublish = getHomeResourceUrl('noWork.png');
export const emptyFriend = getHomeResourceUrl('noFans.png');
export const emptyCollection = getHomeResourceUrl('noWork2.png');
export const emptySearch = getHomeResourceUrl('noWork.png');

// 消息页
export const systemNull = getHomeResourceUrl('noWork2.png');
export const interactionNull = getHomeResourceUrl('noWork2.png');

// Category页选项卡数据
export const categoryTags = [{
  title: '全部',
  type: ''
}, {
  title: '精选',
  icon: 'king',
  color: 'yellow',
  type: '精选'
}, {
  title: '创智源',
  color: 'green',
  type: '创智源'
}, {
  title: '游戏',
  icon: '',
  color: '',
  type: '游戏'
}, {
  title: '艺术',
  color: '',
  type: '艺术'
}, {
  title: '动画',
  color: '',
  type: '动画'
}, {
  title: '音乐',
  color: '',
  type: '音乐'
}, {
  title: '故事',
  color: '',
  type: '故事'
}, {
  title: '模拟',
  color: '',
  type: '模拟'
}, {
  title: '生活',
  color: '',
  type: '生活'
}, {
  title: '科技',
  color: '',
  type: '科技'
}, {
  title: '其他',
  color: '',
  type: '其他'
}];

// 轮播数据
export const SliderSources = [
  {
    src: swipers[0],
    href: '/'
  },
  {
    src: swipers[1],
    href: '/'
  },
  {
    src: swipers[2],
    href: '/'
  }
];

// 专题数据 默认读取第3张轮播图
export const topicSources = [
  {
    href: '/',
    src: swipers[2],
  },
  {
    href: '/',
    src: swipers[2],
  },
  {
    href: '/',
    src: swipers[2],
  },
  {
    href: '/',
    src: swipers[2],
  }
]

export const wxLoginUrl = () => {
  const origin = 'https://open.weixin.qq.com/connect/qrconnect';
  const paramString = queryStringify({
    ...WXOpenInfo,
    state: `redirect=${window.location.href}`
  });
  return origin + paramString;
}

// 获取微信登录接口地址
export const wxBindUserUrl = (userId) => {
  const origin = 'https://open.weixin.qq.com/connect/qrconnect';
  const paramString = queryStringify({
    ...WXOpenInfo,
    state: `redirect=${window.location.href}__bindId=${userId}`
  });
  return origin + paramString;
}

// 地址映射
export const homeUrls = {
  docCzy: 'http://doc.k12-code.com/czysms', // 文档 创智源文档
  docBcb: 'http://doc.k12-code.com/bcb' // 文档 编程板文档
}

// 获取作品域名地址（便于测试移动端，请酌情传入线上的id以便于测试）
export const getWorkDomainUrl = (id) => {
  return `http://k12-code.com/work/${id}`;
}
